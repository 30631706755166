<template>
  <v-card color="white" ref="slideRight" class="border-radius-sm mt-4">
    <v-card-title
      class="
        font-weight-bold
        text-h5
        pb-0
        d-flex
        align-center
        justify-space-between
      "
    >
      路演和调研热度
    </v-card-title>
    <div class="pa-4" v-if="loading">加载中</div>
    <v-card-text class="pa-4" v-if="!loading">
      <v-btn-toggle dense borderless v-model="tab" mandatory>
        <v-btn text :disabled="loading">按个股</v-btn>
        <v-btn text :disabled="loading">按行业</v-btn>
      </v-btn-toggle>
      <v-divider></v-divider>

      <div style="display: flex" v-show="tab == 0">
        <div style="width: 50%">
          <span style="font-weight: bolder" class="text-dark">最近30天</span>
          <div>
            <div
              v-for="(item, index) in pageData.stock.month"
              style="
                width: 200px;
                display: flex;
                justify-content: space-between;
              "
            >
              <span style="font-weight: bolder" class="text-dark">{{
                index + 1
              }}</span>
              <span>{{ item.name }} ({{ item.count }})</span>
            </div>
          </div>
        </div>
        <div style="width: 50%">
          <span style="font-weight: bolder" class="text-dark">未来7天</span>
          <div>
            <div
              v-for="(item, index) in pageData.stock.week"
              style="
                width: 200px;
                display: flex;
                justify-content: space-between;
              "
            >
              <span style="font-weight: bolder" class="text-dark">{{
                index + 1
              }}</span>
              <span>{{ item.name }} ({{ item.count }})</span>
            </div>
          </div>
        </div>
      </div>

      <div style="display: flex" v-show="tab == 1">
        <div style="width: 50%">
          <span style="font-weight: bolder" class="text-dark">最近30天</span>
          <div>
            <div
              v-for="(item, index) in pageData.industry.month"
              style="
                width: 200px;
                display: flex;
                justify-content: space-between;
              "
            >
              <span style="font-weight: bolder" class="text-dark">{{
                index + 1
              }}</span>
              <span>{{ item.name }} ({{ item.count }})</span>
            </div>
          </div>
        </div>
        <div style="width: 50%">
          <span style="font-weight: bolder" class="text-dark">未来7天</span>
          <div>
            <div
              v-for="(item, index) in pageData.industry.week"
              style="
                width: 200px;
                display: flex;
                justify-content: space-between;
              "
            >
              <span style="font-weight: bolder" class="text-dark">{{
                index + 1
              }}</span>
              <span>{{ item.name }} ({{ item.count }})</span>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import User from "@/api/user.js";
import Out from "@/api/out.js";
import { mount } from "sortablejs";
export default {
  data() {
    return {
      loading: false,
      pageData: {},
      tab: 0,
    };
  },
  props: {},
  computed: {},

  watch: {},

  mounted() {
    this.getResearchStatistics();
  },

  methods: {
    getResearchStatistics() {
      this.loading = true;
      Out.getResearchStatistics()
        .then((res) => {
          console.log(res.data);
          this.pageData = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
