//公告
<template>
  <v-list-item-content class="pb-0">
    <v-row class="d-flex align-center" no-gutters>
      <!-- <div>
        <span class="font-weight-bold text-subtitle-2"
          >{{ str.data.stock_name }}
        </span>
      </div>
      <b>&nbsp;|&nbsp;</b> -->
      <span class="font-weight-bold text-dark" style="opacity: 0.5">公告</span>
    </v-row>
    <v-list-item-title
      class="font-weight-bold mt-1"
      style="overflow: inherit; white-space: normal; line-height: 24px"
    >
      {{ str.data.title }}
    </v-list-item-title>
    <v-list-item-subtitle
      class="desc"
      style="color: #191a1d; line-height: 22px"
    >
      <a
        :href="str.data.url"
        :download="str.data.url"
        target="_blank"
        class="text-xs font-weight-normal text-typo mr-2"
        >打开公告</a
      >
    </v-list-item-subtitle>
    <v-list-item-subtitle
      class="py-2 d-flex align-center justify-space-between"
    >
      <v-row no-gutters class="d-flex align-center">
        <v-btn
          elevation="1"
          small
          :ripple="false"
          height="21"
          class="
            border-radius-sm
            text-xxs text-blue
            me-1
            font-weight-bold
            px-1
            py-1
            badge-font-size
          "
          @click="$stockHelpers.openStockDetail(str.data.stock.id)"
          >{{ str.data.stock.name }}</v-btn
        >
      </v-row>

      <div>{{ str.date }}</div>
    </v-list-item-subtitle>
  </v-list-item-content>
</template>
<script>
export default {
  props: ["str", "tab"],
  data() {
    return {};
  },
};
</script>
