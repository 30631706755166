<template>
  <div class="px-4 pb-3" style="display: flex" v-if="!forceRefresh">
    <vxe-table
      style="width: 120px"
      :data="tagList1"
      :row-class-name="'red lighten-5 tagList1'"
      class="my-scrollbar"
      border
      show-overflow
      show-header-overflow
      highlight-hover-row
      ref="tagList1"
      :loading="tagTableLoading"
      :height="pageHeight - 180 + 'px'"
      size="small"
      :stripe="true"
      :sort-config="{
        trigger: 'cell',
        defaultSort: { field: 'pinyin', order: 'asc' },
        orders: ['desc', 'asc', null],
        multiple: true,
      }"
      :scroll-x="{ gt: 30 }"
      :scroll-y="{ mode: 'wheel', gt: 100 }"
      @cell-click="tagClick"
      :tooltip-config="{
        showAll: false,
        enterable: true,
        theme: 'dark',
      }"
      :export-config="{}"
    >
      <vxe-column
        fixed="left"
        field="name"
        :title="'核心 (' + tagList1.length + '个)'"
      >
        <template #default="{ row }">
          <div
            style="cursor: pointer; width: 100%; height: 100%"
            class="drag-btn"
            :data-id="row.id"
          >
            {{ row.selected ? "⭐" : "" }} {{ row.name }}
          </div>
        </template>
      </vxe-column>
      <vxe-column field="pinyin" title="拼音" sortable :visible="false">
        <template #default="{ row }">
          {{ row.pinyin }}
        </template>
      </vxe-column>
      <vxe-column
        field="user_id"
        title="跟踪人"
        sortable
        :visible="false"
        :filters="[{ data: '' }]"
        :filter-method="
          (value) => $tableHelpers.filterInputMethod(value, 'user_id')
        "
      >
        <template #filter="{ $panel, column }">
          <template v-for="(option, index) in column.filters">
            <input
              class="pa-2"
              type="type"
              :key="index"
              v-model="option.data"
              @input="$panel.changeOption($event, !!option.data, option)"
              @keyup.enter="$panel.confirmFilter()"
              placeholder="按回车确认筛选"
            />
          </template>
        </template>
        <template #default="{ row }">
          {{ row.user_id }}
        </template>
      </vxe-column>
    </vxe-table>

    <vxe-table
      style="width: 120px"
      :data="tagList2"
      :row-class-name="'orange lighten-5 tagList2'"
      class="my-scrollbar"
      border
      show-overflow
      show-header-overflow
      highlight-hover-row
      ref="tagList2"
      :loading="tagTableLoading"
      :height="pageHeight - 180 + 'px'"
      size="small"
      :stripe="true"
      :sort-config="{
        trigger: 'cell',
        defaultSort: { field: 'pinyin', order: 'asc' },
        orders: ['desc', 'asc', null],
        multiple: true,
      }"
      :scroll-x="{ gt: 30 }"
      :scroll-y="{ mode: 'wheel', gt: 100 }"
      @cell-click="tagClick"
      :tooltip-config="{
        showAll: false,
        enterable: true,
        theme: 'dark',
      }"
    >
      <vxe-column
        fixed="left"
        field="name"
        :title="'一级 (' + tagList2.length + '个)'"
      >
        <template #default="{ row }">
          <div
            style="cursor: pointer; width: 100%; height: 100%"
            class="drag-btn"
            :data-id="row.id"
          >
            {{ row.selected ? "⭐" : "" }} {{ row.name }}
          </div>
        </template>
      </vxe-column>
      <vxe-column field="pinyin" title="拼音" sortable :visible="false">
        <template #default="{ row }">
          {{ row.pinyin }}
        </template>
      </vxe-column>
      <vxe-column
        field="user_id"
        title="跟踪人"
        sortable
        :visible="false"
        :filters="[{ data: '' }]"
        :filter-method="
          (value) => $tableHelpers.filterInputMethod(value, 'user_id')
        "
      >
        <template #filter="{ $panel, column }">
          <template v-for="(option, index) in column.filters">
            <input
              class="pa-2"
              type="type"
              :key="index"
              v-model="option.data"
              @input="$panel.changeOption($event, !!option.data, option)"
              @keyup.enter="$panel.confirmFilter()"
              placeholder="按回车确认筛选"
            />
          </template>
        </template>
        <template #default="{ row }">
          {{ row.user_id }}
        </template>
      </vxe-column>
    </vxe-table>

    <vxe-table
      style="width: 120px"
      :data="tagList3"
      :row-class-name="'green lighten-5 tagList3'"
      class="my-scrollbar"
      border
      show-overflow
      show-header-overflow
      highlight-hover-row
      ref="tagList3"
      :loading="tagTableLoading"
      :height="pageHeight - 180 + 'px'"
      size="small"
      :stripe="true"
      :sort-config="{
        trigger: 'cell',
        defaultSort: { field: 'pinyin', order: 'asc' },
        orders: ['desc', 'asc', null],
        multiple: true,
      }"
      :scroll-x="{ gt: 30 }"
      :scroll-y="{ mode: 'wheel', gt: 100 }"
      @cell-click="tagClick"
      :tooltip-config="{
        showAll: false,
        enterable: true,
        theme: 'dark',
      }"
    >
      <vxe-column
        fixed="left"
        field="name"
        :title="'普通 (' + tagList3.length + '个)'"
      >
        <template #default="{ row }">
          <div
            style="cursor: pointer; width: 100%; height: 100%"
            class="drag-btn"
            :data-id="row.id"
          >
            {{ row.selected ? "⭐" : "" }} {{ row.name }}
          </div>
        </template>
      </vxe-column>
      <vxe-column field="pinyin" title="拼音" sortable :visible="false">
        <template #default="{ row }">
          {{ row.pinyin }}
        </template>
      </vxe-column>
      <vxe-column
        field="user_id"
        title="跟踪人"
        sortable
        :visible="false"
        :filters="[{ data: '' }]"
        :filter-method="
          (value) => $tableHelpers.filterInputMethod(value, 'user_id')
        "
      >
        <template #filter="{ $panel, column }">
          <template v-for="(option, index) in column.filters">
            <input
              class="pa-2"
              type="type"
              :key="index"
              v-model="option.data"
              @input="$panel.changeOption($event, !!option.data, option)"
              @keyup.enter="$panel.confirmFilter()"
              placeholder="按回车确认筛选"
            />
          </template>
        </template>
        <template #default="{ row }">
          {{ row.user_id }}
        </template>
      </vxe-column>
    </vxe-table>

    <vxe-table
      style="width: 120px"
      :data="tagList4"
      :row-class-name="'blue-grey lighten-5 tagList4'"
      class="my-scrollbar"
      border
      show-overflow
      show-header-overflow
      highlight-hover-row
      ref="tagList4"
      :loading="tagTableLoading"
      :height="pageHeight - 180 + 'px'"
      size="small"
      :stripe="true"
      :sort-config="{
        trigger: 'cell',
        defaultSort: { field: 'pinyin', order: 'asc' },
        orders: ['desc', 'asc', null],
        multiple: true,
      }"
      :scroll-x="{ gt: 30 }"
      :scroll-y="{ mode: 'wheel', gt: 100 }"
      @cell-click="tagClick"
      :tooltip-config="{
        showAll: false,
        enterable: true,
        theme: 'dark',
      }"
    >
      <vxe-column
        fixed="left"
        field="name"
        :title="'行业风格 (' + tagList4.length + '个)'"
      >
        <template #default="{ row }">
          <div
            style="cursor: pointer; width: 100%; height: 100%"
            class="drag-btn"
            :data-id="row.id"
          >
            {{ row.selected ? "⭐" : "" }} {{ row.name }}
          </div>
        </template>
      </vxe-column>
      <vxe-column field="pinyin" title="拼音" sortable :visible="false">
        <template #default="{ row }">
          {{ row.pinyin }}
        </template>
      </vxe-column>
      <vxe-column
        field="user_id"
        title="跟踪人"
        sortable
        :visible="false"
        :filters="[{ data: '' }]"
        :filter-method="
          (value) => $tableHelpers.filterInputMethod(value, 'user_id')
        "
      >
        <template #filter="{ $panel, column }">
          <template v-for="(option, index) in column.filters">
            <input
              class="pa-2"
              type="type"
              :key="index"
              v-model="option.data"
              @input="$panel.changeOption($event, !!option.data, option)"
              @keyup.enter="$panel.confirmFilter()"
              placeholder="按回车确认筛选"
            />
          </template>
        </template>
        <template #default="{ row }">
          {{ row.user_id }}
        </template>
      </vxe-column>
    </vxe-table>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import XEUtils from "xe-utils";
import Tag from "@/api/tag.js";
import Sortable from "sortablejs";
export default {
  name: "TagListWithLevel",
  components: {},
  props: {
    currTag: {},
    pageHeight: null,
    selectedIds: [],
  },
  data() {
    return {
      forceRefresh: false,
      tagTableLoading: false,
      filterName: "",

      sortable: [],

      tagList1: [],
      tagData1: [],
      tagList2: [],
      tagData2: [],
      tagList3: [],
      tagData3: [],
      tagList4: [],
      tagData4: [],
    };
  },
  mounted() {
    this.getTagList();
    Bus.$on("refreshTagList", (e) => {
      this.getTagList();

      this.forceRefresh = true;
      for (let index = 1; index < 5; index++) {
        this.sortable[index - 1].destroy();
      }

      setTimeout(() => {
        this.forceRefresh = false;
        this.treeDrop();
      }, 100);
    });
  },
  created() {
    this.treeDrop();
  },
  methods: {
    userSelect(val) {
      //console.log(val);
      this.$tableHelpers.filterInput(this.$refs.tagList1, "user_id", val);
      this.$tableHelpers.filterInput(this.$refs.tagList2, "user_id", val);
      this.$tableHelpers.filterInput(this.$refs.tagList3, "user_id", val);
      this.$tableHelpers.filterInput(this.$refs.tagList4, "user_id", val);
    },
    tagClick({ row }) {
      this.$emit("tagClick", { row });
    },
    getTagList() {
      this.filterName = "";
      this.tagTableLoading = true;
      //this.exportList = [];
      Tag.getRaceList()
        .then((res) => {
          //console.log("getTagList4");
          //this.tableData = res.data;
          let lv0 = [];
          let lv1 = [];
          let lv2 = [];
          let lv3 = [];
          res.data.forEach((item) => {
            //console.log(index);
            if (item.level == 1) {
              lv1.push(item);
            } else if (item.level == 2) {
              lv2.push(item);
            } else if (item.level == 3) {
              lv3.push(item);
            } else if (item.level == 0) {
              lv0.push(item);
            }
          });
          this.tagList4 = lv0;
          this.tagData4 = lv0;
          this.tagList1 = lv1;
          this.tagData1 = lv1;
          this.tagList2 = lv2;
          this.tagData2 = lv2;
          this.tagList3 = lv3;
          this.tagData3 = lv3;

          //处理已选择
          console.log(this.selectedIds);
          this.tagList1.forEach((tag) => {
            if (this.selectedIds.indexOf(tag.id) != -1) {
              tag.selected = true;
            } else {
              tag.selected = false;
            }
          });
          this.tagList2.forEach((tag) => {
            if (this.selectedIds.indexOf(tag.id) != -1) {
              tag.selected = true;
            } else {
              tag.selected = false;
            }
          });
          this.tagList3.forEach((tag) => {
            if (this.selectedIds.indexOf(tag.id) != -1) {
              tag.selected = true;
            } else {
              tag.selected = false;
            }
          });
          this.tagList4.forEach((tag) => {
            if (this.selectedIds.indexOf(tag.id) != -1) {
              tag.selected = true;
            } else {
              tag.selected = false;
            }
          });
        })
        .catch((err) => {
          console.log(err);
          console.log(123123);
          //this.loginError();
        })
        .finally(() => {
          this.tagTableLoading = false;
        });
    },
    searchEvent(filter) {
      this.filterName = filter;
      const filterName = XEUtils.toValueString(this.filterName)
        .trim()
        .toLowerCase();
      if (filterName) {
        const filterRE = new RegExp(filterName, "gi");
        const searchProps = ["name", "pinyin"];
        this.tagList1 = this.tagData1.filter((item) =>
          searchProps.some(
            (key) =>
              XEUtils.toValueString(item[key])
                .toLowerCase()
                .indexOf(filterName) > -1
          )
        );
        this.tagList2 = this.tagData2.filter((item) =>
          searchProps.some(
            (key) =>
              XEUtils.toValueString(item[key])
                .toLowerCase()
                .indexOf(filterName) > -1
          )
        );
        this.tagList3 = this.tagData3.filter((item) =>
          searchProps.some(
            (key) =>
              XEUtils.toValueString(item[key])
                .toLowerCase()
                .indexOf(filterName) > -1
          )
        );
        this.tagList4 = this.tagData4.filter((item) =>
          searchProps.some(
            (key) =>
              XEUtils.toValueString(item[key])
                .toLowerCase()
                .indexOf(filterName) > -1
          )
        );
      } else {
        this.tagList1 = this.tagData1;
        this.tagList2 = this.tagData2;
        this.tagList3 = this.tagData3;
        this.tagList4 = this.tagData4;
      }
      //return this.tableData;
    },

    treeDrop() {
      this.$nextTick(() => {
        const xTable1 = this.$refs.tagList1;
        const xTable2 = this.$refs.tagList2;
        const xTable3 = this.$refs.tagList3;
        const xTable4 = this.$refs.tagList4;

        for (let index = 1; index < 5; index++) {
          this.sortable[index - 1] = Sortable.create(
            this.$refs["tagList" + index].$el.querySelector(
              ".body--wrapper>.vxe-table--body tbody"
            ),
            {
              handle: ".drag-btn",
              onAdd: (evt) => {
                //console.log("add", evt);
                let toLevel = index == 4 ? 0 : index;
                let fromLevel = 1;
                if (evt.item._prevClass.indexOf("tagList1") > -1) {
                  fromLevel = 1;
                } else if (evt.item._prevClass.indexOf("tagList2") > -1) {
                  fromLevel = 2;
                } else if (evt.item._prevClass.indexOf("tagList3") > -1) {
                  fromLevel = 3;
                } else if (evt.item._prevClass.indexOf("tagList4") > -1) {
                  fromLevel = 0;
                }
                console.log(
                  toLevel,
                  fromLevel,
                  evt.item.cells[0].childNodes[0].childNodes[0].attributes[
                    "data-id"
                  ].value
                );
                //操作修改层级
                this.showLoading("保存中，请稍候");
                Tag.editTag(
                  evt.item.cells[0].childNodes[0].childNodes[0].attributes[
                    "data-id"
                  ].value,
                  {
                    level: toLevel,
                  }
                )
                  .then((res) => {
                    this.showToast("修改成功", "success", 5000);
                    Bus.$emit("refreshCoreStockPool", true);
                    Bus.$emit("refreshTagList", true);
                    Bus.$emit("refreshTagDetail", true);
                  })
                  .catch((err) => {
                    console.log(err.msg);
                    this.showToast(err.msg, "danger", 10000);
                  })
                  .finally(() => {
                    this.hideLoading();
                  });
              },
              group: "shared", // set both lists to same group
              animation: 150,
            }
          );
        }
      });
    },
  },
  watch: {
    selectedIds: {
      handler: function (val) {
        this.tagList1.forEach((tag) => {
          if (this.selectedIds.indexOf(tag.id) != -1) {
            tag.selected = true;
          } else {
            tag.selected = false;
          }
        });
        this.tagList2.forEach((tag) => {
          if (this.selectedIds.indexOf(tag.id) != -1) {
            tag.selected = true;
          } else {
            tag.selected = false;
          }
        });
        this.tagList3.forEach((tag) => {
          if (this.selectedIds.indexOf(tag.id) != -1) {
            tag.selected = true;
          } else {
            tag.selected = false;
          }
        });
        this.tagList4.forEach((tag) => {
          if (this.selectedIds.indexOf(tag.id) != -1) {
            tag.selected = true;
          } else {
            tag.selected = false;
          }
        });
        //刷新表格
        if (this.$refs.tagList1) {
          this.$refs.tagList1.loadData(this.tagList1);
          this.$refs.tagList2.loadData(this.tagList2);
          this.$refs.tagList3.loadData(this.tagList3);
          this.$refs.tagList4.loadData(this.tagList4);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
